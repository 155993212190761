/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";

import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Events from "layouts/Events";
import CustomForms from "layouts/CustomForms";
import NewsAndMedia from "layouts/NewsAndMedia";
import GrowthLab from "layouts/GrowthLab";
import EventForm from "layouts/Events/EventForm";
import CreateCustomForm from "layouts/CustomForms/CreateCustomForm";
import Associations from "layouts/Associations";
import AssociationForm from "layouts/Associations/AssociationForm";
import AwardsRecognition from "layouts/AwardsRecognition";
import GrowthLabForm from "layouts/GrowthLab/GrowthlabForm";
import Banners from "layouts/Banners";
import BannerForm from "layouts/Banners/BannerForm";
import NewsAndMediaForm from "layouts/NewsAndMedia/NewsAndMediaForm/NewsAndMediaForm";
import UserQueries from "layouts/UserQueries";
import AwardsRecognitionForm from "layouts/AwardsRecognition/AwardsRecognitionForm/AwardsRecognitionForm";
import Announcements from "layouts/Announcements";
import AnnouncementForm from "layouts/Announcements/AnnouncementForm/AnnouncementForm";

const routes = [
  {
    // type: "collapse",
    // name: "Dashboard",
    // key: "dashboard",
    // icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  {
    type: "collapse",
    name: "Banners",
    key: "banners",
    icon: <Icon fontSize="small">dynamic_feed_icon</Icon>,
    route: "/banners",
    component: <Banners />,
  },
  {
    key: "add-banners",
    route: "/banners/:tag",
    component: <BannerForm />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">stadium</Icon>,
    route: "/events",
    component: <Events />,
  },
  {
    key: "add-events",
    route: "/events/:tag",
    component: <EventForm />,
  },
  {
    type: "collapse",
    name: "Associations",
    key: "association",
    icon: <Icon fontSize="small">device_hub_icon</Icon>,
    route: "/associations",
    component: <Associations />,
  },
  {
    key: "add-association",
    route: "/associations/:tag",
    component: <AssociationForm />,
  },
  {
    type: "collapse",
    name: "News and Media",
    key: "news and media",
    icon: <Icon fontSize="small">newspaper</Icon>,
    route: "/news-and-media",
    component: <NewsAndMedia />,
  },
  {
    key: "news-and-media",
    route: "/news-and-media/:tag",
    component: <NewsAndMediaForm />,
  },
  {
    type: "collapse", 
    name: "Announcements",
    key: "announcements",
    icon: <Icon fontSize="small">campaign</Icon>,
    route: "/announcements",
    component: <Announcements />,
  },
  {
    key: "announcements",
    route: "/announcements/:tag",
    component: <AnnouncementForm />,
  },
  {
    type: "collapse",
    name: "Growth lab",
    key: "growth lab",
    icon: <Icon fontSize="small">auto_graph</Icon>,
    route: "/growth-lab",
    component: <GrowthLab />,
  },
  {
    key: "add-growth-lab",
    route: "/growth-lab/:tag",
    component: <GrowthLabForm />,
  },
  {
    type: "collapse",
    name: "Awards and recognition",
    key: "Awards and recognition",
    icon: <Icon fontSize="small">military_tech</Icon>,
    route: "/awards-and-recognition",
    component: <AwardsRecognition />,
  },
  {
    key: "awards-and-recognition",
    route: "/awards-and-recognition/:tag",
    component: <AwardsRecognitionForm />,
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: "collapse",
    name: "User Queries",
    key: "User Queries",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/user-queries",
    component: <UserQueries />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    // type: "collapse",
    // name: "Custom Forms",
    // key: "customforms",
    icon: <Icon fontSize="small">stadium</Icon>,
    route: "/customforms",
    component: <CustomForms />,
  },
  {
    key: "add-custom-forms",
    route: "/customforms/:tag",
    component: <CreateCustomForm />,
  },
  {
    // type: "collapse",
    // name: "Sign In",
    key: "sign-in",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
