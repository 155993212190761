import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    banners: [],
    bannerById: null
};

const BannerReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_BANNER_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_BANNER_SUCCESS:
            return { ...state, loading: false, banners: payload };
        case actionTypes.GET_BANNER_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_BANNER_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_BANNER_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                bannerById: payload,
            };
        case actionTypes.GET_BANNER_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_BANNER_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                banners: [payload, ...state?.banners],
            };
        case actionTypes.ADD_BANNER_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_BANNER_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_BANNER_SUCCESS:
            return { ...state, loading: false, bannerById: payload };
        case actionTypes.UPDATE_BANNER_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default BannerReducer;
