import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    awards: [],
    awardById: null
};

const AwardsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_AWARD_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_AWARD_SUCCESS:
            return { ...state, loading: false, awards: payload };
        case actionTypes.GET_AWARD_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_AWARD_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_AWARD_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                awardById: payload,
            };
        case actionTypes.GET_AWARD_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_AWARD_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_AWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                awards: [payload, ...state.awards],
            };
        case actionTypes.ADD_AWARD_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_AWARD_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_AWARD_SUCCESS:
            return { ...state, loading: false, awardById: payload };
        case actionTypes.UPDATE_AWARD_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.DELETE_AWARD_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.DELETE_AWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                awards: state.awards.filter(awardItem => awardItem.id !== payload),
            };
        case actionTypes.DELETE_AWARD_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default AwardsReducer;
