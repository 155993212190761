import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, Card, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { DeleteAnnouncement, GetAnnouncements } from "../../Redux/action"; // Assuming you have Redux actions

export default function Announcements() {
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    };

    const Author = ({ image, Title }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={Title} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {Title}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ Url, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {Url}
            </MDTypography>
            <MDTypography variant="caption">
                {truncateText(description, 100)}
            </MDTypography>
        </MDBox>
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const { announcements, loading } = useSelector((store) => store.AnnouncementReducer);
    const announcementsData = Array.isArray(announcements) ? announcements : [];
    console.log("announcementsData", announcementsData)

    const handleEditClick = (id) => {
        navigate("/announcements/edit/", { state: { type: "edit", id: id } });
    };

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = async () => {
        try {
            await dispatch(DeleteAnnouncement(deleteId));
            dispatch(GetAnnouncements());
            handleClose();
            enqueueSnackbar("Announcement deleted successfully.", {
                variant: "success",
            });
        } catch (error) {
            console.error("Error deleting announcement:", error);
        }
    };

    const columns = [
        {
            Header: "announcement title",
            accessor: "announcement title",
            align: "left",
            width: "25%",
        },
        {
            Header: "url & description",
            accessor: "url & description",
            align: "left",
            width: "50%",
        },
        { Header: "action", accessor: "action", align: "center" },
    ];

    // ?.filter((announcement) => !announcement.Is_deleted)
    const rows = Array.isArray(announcementsData)
        ? announcementsData.map((data) => ({
            "announcement title": (
                <Author image={`${docUrl}${data?.Images?.[0]}`} Title={`${data?.Title}`} />
            ),
            "url & description": (
                <Job Url={data?.Link} description={data?.Description} />
            ),
            action: (
                <>
                    <MDBadge
                        badgeContent={"Edit"}
                        sx={{ cursor: "pointer" }}
                        fontWeight="medium"
                        onClick={() => handleEditClick(data?.Announcement_id)}
                    />
                    <MDBadge
                        color={"dark"}
                        badgeContent={"Delete"}
                        sx={{ cursor: "pointer" }}
                        fontWeight="medium"
                        onClick={() => handleClickOpen(data?.Announcement_id)}
                    />
                </>
            ),
        }))
        : [];


    const handleGetAnnouncements = async () => {
        try {
            await dispatch(GetAnnouncements());
        } catch (error) {
            console.error("Error fetching announcements:", error);
        }
    };

    useEffect(() => {
        handleGetAnnouncements();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Announcements
                                </MDTypography>
                                <MDBox>
                                    <Tooltip title="Add a new announcement">
                                        <MDButton
                                            onClick={() => {
                                                navigate("/announcements/add", { state: { type: "add" } });
                                            }}
                                            variant="outlined"
                                            color="white"
                                        // startIcon={<PersonAddAlt1Icon />}
                                        >
                                            Announcement
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                {announcementsData && !loading ? (
                                    announcementsData.length > 0 ? (
                                        <DataTable
                                            table={{ columns, rows }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    ) : (
                                        <Grid
                                            style={{
                                                display: "grid",
                                                placeItems: "center",
                                                margin: "15.7%",
                                            }}
                                        >
                                            <img
                                                src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                                                width={"80px"}
                                                height={"80px"}
                                                alt="No data"
                                            />
                                            <MDTypography fontSize="12px">
                                                No announcement found. Please add an announcement to continue.
                                            </MDTypography>
                                        </Grid>
                                    )
                                ) : (
                                    <span style={{ fontSize: "15px", margin: "2rem" }}>Loading...</span>
                                )}
                            </MDBox>

                            <Dialog open={open} onClose={handleClose}>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure you want to delete this announcement?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <MDButton onClick={handleClose}>Disagree</MDButton>
                                    <MDButton onClick={handleDeleteClick} autoFocus>
                                        Agree
                                    </MDButton>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
