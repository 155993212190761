import React, { useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Tooltip,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteNews, GetAwards, GetNews } from "../../Redux/action";
import { useState } from "react";
import { useSnackbar } from "notistack";

export default function AwardsRecognition() {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    const Author = ({ image, name, Received_by, Award_date }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">
                    Received by {Received_by} on {Award_date?.slice(0, 10)}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    // const Job = ({ title, description }) => (
    //     <MDBox lineHeight={1} textAlign="left">
    //         <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
    //             {title}
    //         </MDTypography>
    //         <MDTypography variant="caption">{description}</MDTypography>
    //     </MDBox>
    // );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    // const [open, setOpen] = useState(false);
    // const [deleteId, setDeleteId] = useState(null);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const { awards, loading } = useSelector((store) => store.AwardsReducer);
    const awardsData = awards || [];
    console.log("awardsData", awards);

    const handleEditClick = (id) => {
        navigate("/awards-and-recognition/edit", {
            state: { type: "edit", id: id },
        });
    };

    // const handleClickOpen = (id) => {
    //     setDeleteId(id);
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleDeleteClick = async () => {
    //     try {
    //         await dispatch(DeleteNews(deleteId));
    //         dispatch(GetNews());
    //         handleClose();
    //         enqueueSnackbar("Data deleted successfully.", {
    //             variant: "success",
    //         });
    //     } catch (error) {
    //         console.error("Error deleting equipment:", error);
    //     }
    // };

    const columns = [
        {
            Header: "award name",
            accessor: "award name",
            align: "left",
            width: "25%",
        },
        {
            Header: "description",
            accessor: "description",
            align: "center",
            width: "60%",
        },
        // { Header: "status", accessor: "status", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
    ];

    const rows = awardsData
        // ?.filter((news) => news.Is_deleted === 0)
        ?.map((data) => ({
            "award name": (
                <Author
                    image={`${docUrl}${data?.Images[0]}`}
                    name={data?.Name}
                    Received_by={data?.Received_by}
                    Award_date={data?.Award_date}
                />
            ),
            description: (
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                >
                    {truncateText(data?.Description, 70)}
                </MDTypography>
            ),

            //   status: (
            //     <MDBox ml={-1}>
            //       <MDBadge
            //         badgeContent={data?.Status}
            //         color={data?.Status === "active" ? "success" : "dark"}
            //         variant="gradient"
            //         size="sm"
            //         // onClick={handleToggle}
            //         // style={{ cursor: 'pointer' }}
            //       />
            //     </MDBox>
            //   ),
            action: (
                <>
                    <MDBadge
                        badgeContent={"Edit"}
                        sx={{ cursor: "pointer" }}
                        fontWeight="medium"
                        onClick={() => handleEditClick(data?.Award_id)}
                    />
                    {/* <MDBadge
                    color={"dark"}
                    badgeContent={"Delete"}
                    sx={{ cursor: "pointer" }}
                    fontWeight="medium"
                    onClick={() => {
                        if (data?.Award_Id) handleClickOpen(data?.Award_Id);
                    }}
                /> */}
                </>
            ),
        }));

    const handleGetUser = async () => {
        try {
            await dispatch(GetAwards());
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        handleGetUser();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Awards and Recognition
                                </MDTypography>
                                <MDBox className="ticket_btnsHeaderCont">
                                    <Tooltip title="Add new data">
                                        <MDButton
                                            onClick={() => {
                                                navigate("/awards-and-recognition/add", {
                                                    state: { type: "add" },
                                                });
                                            }}
                                            variant="outlined"
                                            color="white"
                                            startIcon={<PersonAddAlt1Icon />}
                                        >
                                            Awards and Recognition
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                {awardsData && !loading ? (
                                    awardsData?.length > 0 ? (
                                        <DataTable
                                            table={{ columns, rows }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    ) : (
                                        <Grid
                                            style={{
                                                display: "grid",
                                                placeItems: "center",
                                                margin: "15.7%",
                                            }}
                                        >
                                            <img
                                                src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                                                width={"80px"}
                                                height={"80px"}
                                                alt=""
                                            />
                                            <MDTypography fontSize="12px">
                                                No data found. Please add an award to continue.
                                            </MDTypography>
                                        </Grid>
                                    )
                                ) : (
                                    <span style={{ fontSize: "15px", margin: "2rem" }}>
                                        Loading...
                                    </span>
                                )}
                            </MDBox>

                            {/* <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this award?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <MDButton onClick={handleClose}>
                                        Disagree
                                    </MDButton>
                                    <MDButton
                                        onClick={handleDeleteClick}
                                        autoFocus
                                    >
                                        Agree
                                    </MDButton>
                                </DialogActions>
                            </Dialog> */}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
