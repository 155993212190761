import * as React from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
    AddGrowthLab,
    GetGrowthLabById,
    UpdateGrowthLab,
} from "../../../Redux/action";
import { useEffect } from "react";

const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
        width: "100%",
    },
};

const menuItemsOfSector = [
    "Open Source and Open Platform Technology",
    "Renewable Energy and Clean Technology",
    "Pharma and Biotechnology",
    "Agri and Food Processing Technology",
    "Mobile Computing",
    "IT and IT Enabled Services",
    "Project Management",
    "Data Centers",
    "Social Incubation",
    "Marine Services",
    "Cyber Security",
    "GeoInformatics",
    "Education",
    "Electronics and Telecommunication",
];

const GrowthLabForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const type = location?.state?.type;
    const idFromLocation = location?.state?.id;
    const { growthLabById } = useSelector((store) => store.GrowthLabReducer);

    const payloadObj = {
        Company_name: "",
        Contact_person: "",
        Description: "",
        Website: "",
        Sector: "",
        Status: "",
        User_role: "admin",
    };

    const [payload, setPayload] = useState(
        type === "edit" && growthLabById ? { ...growthLabById } : payloadObj
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayload({ ...payload, [name]: value });
    };

    const handleResponseAddGrowthLab = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`${"Data added successfully."}`, { variant: "success" });
            navigate("/growth-lab");
        } else {
            if (res?.data?.message) {
                enqueueSnackbar(`${res?.data?.message}`, { variant: "error" });
            } else if (res?.data?.error) {
                enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
            } else {
                enqueueSnackbar(`Something went wrong, Please check all fields.`, {
                    variant: "error",
                });
            }
        }
    };

    const handleResponseUpdateGrowthLab = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Data updated successfully.`, { variant: "success" });
            navigate("/growth-lab");
        } else {
            enqueueSnackbar(
                `${res?.data?.error || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleClick = () => {
        if (type === "add") {
            dispatch(AddGrowthLab(payload, handleResponseAddGrowthLab));
        } else {
            dispatch(
                UpdateGrowthLab(idFromLocation, payload, handleResponseUpdateGrowthLab)
            );
        }
    };

    useEffect(() => {
        if (idFromLocation) {
            dispatch(GetGrowthLabById(idFromLocation));
        }
    }, [idFromLocation, dispatch]);

    useEffect(() => {
        if (idFromLocation) {
            setPayload(growthLabById);
        }
    }, [growthLabById, idFromLocation]);

    useEffect(() => {
        if (type === "add") {
            setPayload(payloadObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("type", type);
    console.log("idFromLocation", idFromLocation);
    // console.log('fileInputs', fileInputs);
    // console.log('growthLabById', growthLabById);
    console.log("payload", payload);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={3}
                p={2}
                mb={1}
                textAlign="center"
            >
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                    Growth Lab Management
                </MDTypography>
            </MDBox>
            <MDBox
                mx={2}
                mt={3}
                py={3}
                p={4}
                mb={2}
                borderRadius="lg"
                variant="gradient"
                coloredShadow="info"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    gap={3}
                    pb={2}
                >
                    <MDInput
                        required
                        type="text"
                        label="Name of the company"
                        name="Company_name"
                        value={payload?.Company_name || ""}
                        margin="dense"
                        fullWidth
                        onChange={handleChange}
                    />
                    <TextField
                        required
                        label="Description"
                        name="Description"
                        value={payload?.Description || ""}
                        multiline
                        rows={4} // Adjust the number of rows as needed
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                    <MDInput
                        required
                        type="text"
                        label="Website"
                        name="Website"
                        value={payload?.Website || ""}
                        margin="dense"
                        sx={widthStyle}
                        onChange={handleChange}
                    />
                    <FormControl required sx={widthStyle}>
                        <InputLabel>Select Sector</InputLabel>
                        <Select
                            style={{ height: "45px" }}
                            label="Select Sector"
                            name="Sector"
                            value={payload?.Sector || ""}
                            onChange={handleChange}
                        >
                            {menuItemsOfSector?.map((item, i) => (
                                <MenuItem key={i} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <MDInput
                        required
                        type="text"
                        label="Contact person"
                        name="Contact_person"
                        value={payload?.Contact_person || ""}
                        margin="dense"
                        sx={widthStyle}
                        onChange={handleChange}
                    />
                    <FormControl sx={{ width: "45%" }} component="fieldset" required>
                        <FormLabel>Status</FormLabel>
                        <RadioGroup
                            row
                            aria-label="status"
                            name="Status"
                            value={payload?.Status || ""}
                            onChange={handleChange}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <FormControlLabel
                                value="active"
                                control={<Radio />}
                                label="Active"
                            />
                            <FormControlLabel
                                value="inactive"
                                control={<Radio />}
                                label="Inactive"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </MDBox>
            <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
                <MDButton variant="gradient" color="info" onClick={handleClick}>
                    {type === "add" ? "Add" : "Update"}
                </MDButton>
            </Grid>
        </DashboardLayout>
    );
};

export default GrowthLabForm;
