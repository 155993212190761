import React, { useEffect, useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    TextField,
    Input,
} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteGrowthLab, GetGrowthLab } from "../../Redux/action";
import { useSnackbar } from "notistack";
import axios from "axios";

export default function GrowthLab() {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    const Author = ({ image, name }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                {/* <MDTypography variant="caption">{email}</MDTypography> */}
            </MDBox>
        </MDBox>
    );

    const Job = ({ website, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography
                display="block"
                variant="caption"
                color="text"
                fontWeight="medium"
            >
                {website}
            </MDTypography>
            <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [fileModalOpen, setFileModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const { growthLabs, loading } = useSelector(
        (store) => store.GrowthLabReducer
    );
    const growthLabsData = growthLabs || [];
    // console.log('growthLabsData', growthLabs);

    const handleEditClick = (id) => {
        navigate("/growth-lab/edit/", { state: { type: "edit", id: id } });
    };

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileModalOpen = () => {
        setFileModalOpen(true);
    };

    const handleFileModalClose = () => {
        setFileModalOpen(false);
        setSelectedFile(null);
    };

    const handleFileChange = (e) => {
        if (
            e.target.files[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            setSelectedFile(e.target.files[0]);
        } else {
            enqueueSnackbar("Please select a valid .xlsx file.", {
                variant: "error",
            });
        }
    };

    const handleFileUpload = async () => {
        try {
            if (selectedFile) {
                const formData = new FormData();
                formData.append("file", selectedFile);
                formData.append("User_role", "admin");

                let res = await axios.post(
                    "http://localhost:8080/growth/importgrowth",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                console.log(res);
                if (res.data.success) {
                    dispatch(GetGrowthLab());
                    enqueueSnackbar("File uploaded successfully.", {
                        variant: "success",
                    });
                } else {
                    enqueueSnackbar("File upload failed.", {
                        variant: "error",
                    });
                }

                console.log("File to be uploaded:", selectedFile);
                handleFileModalClose();
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong", {
                variant: "error",
            });
            console.error("Error while uploading file:", error);
        }
    };

    const handleDeleteClick = async () => {
        try {
            await dispatch(DeleteGrowthLab(deleteId));
            dispatch(GetGrowthLab());
            handleClose();
            enqueueSnackbar("Data is deleted successfully.", {
                variant: "success",
            });
        } catch (error) {
            console.error("Error deleting equipment:", error);
        }
    };

    const columns = [
        { Header: "company name", accessor: "company name", align: "left" },
        { Header: "contact person", accessor: "contact person", align: "left" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "website", accessor: "website", align: "left" },
        { Header: "sector", accessor: "sector", align: "left" },
        { Header: "action", accessor: "action", align: "center" },
    ];

    const rows =
        growthLabsData &&
        growthLabsData
            ?.filter((growth) => growth.Is_deleted === 0)
            ?.map((data) => ({
                "company name": (
                    <Author
                        image={
                            "https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
                        }
                        name={`${data?.Company_name}`}
                    />
                ),
                "contact person": (
                    <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                    >
                        {data?.Contact_person}
                    </MDTypography>
                ),
                website: (
                    <Job
                        website={data?.Website}
                        description={truncateText(data?.Description, 60)}
                    />
                ),
                sector: (
                    <MDTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                    >
                        {truncateText(data?.Sector, 18)}
                    </MDTypography>
                ),

                status: (
                    <MDBox ml={-1}>
                        <MDBadge
                            badgeContent={data?.Status}
                            color={data?.Status === "active" ? "success" : "dark"}
                            variant="gradient"
                            size="sm"
                        // onClick={handleToggle}
                        // style={{ cursor: 'pointer' }}
                        />
                    </MDBox>
                ),
                action: (
                    <>
                        <MDBadge
                            badgeContent={"Edit"}
                            sx={{ cursor: "pointer" }}
                            fontWeight="medium"
                            onClick={() => handleEditClick(data?.Id)}
                        />
                        <MDBadge
                            color={"dark"}
                            badgeContent={"Delete"}
                            sx={{ cursor: "pointer" }}
                            fontWeight="medium"
                            onClick={() => {
                                if (data?.Id) handleClickOpen(data?.Id);
                            }}
                        />
                    </>
                ),
            }));

    const handleGetUser = async () => {
        try {
            await dispatch(GetGrowthLab());
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        handleGetUser();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Growth Lab
                                </MDTypography>
                                <MDBox
                                    className="ticket_btnsHeaderCont"
                                    display="flex"
                                    gap="1rem"
                                >
                                    <Tooltip title="Add new data">
                                        <MDButton
                                            onClick={() => {
                                                navigate("/growth-lab/add", { state: { type: "add" } });
                                            }}
                                            variant="outlined"
                                            color="white"
                                            startIcon={<PersonAddAlt1Icon />}
                                        >
                                            Growth Lab
                                        </MDButton>
                                    </Tooltip>
                                    <Tooltip title="Add file">
                                        <MDButton
                                            onClick={handleFileModalOpen}
                                            variant="outlined"
                                            color="white"
                                            startIcon={<PostAddIcon />}
                                        >
                                            Add file
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                {growthLabsData && !loading ? (
                                    growthLabsData?.length > 0 ? (
                                        <DataTable
                                            table={{ columns, rows }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    ) : (
                                        <Grid
                                            style={{
                                                display: "grid",
                                                placeItems: "center",
                                                margin: "15.7%",
                                            }}
                                        >
                                            <img
                                                src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                                                width={"80px"}
                                                height={"80px"}
                                                alt=""
                                            />
                                            <MDTypography fontSize="12px">
                                                No data found. Please add data to continue.
                                            </MDTypography>
                                        </Grid>
                                    )
                                ) : (
                                    <span style={{ fontSize: "15px", margin: "2rem" }}>
                                        Loading...
                                    </span>
                                )}
                            </MDBox>

                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this data?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <MDButton onClick={handleClose}>Disagree</MDButton>
                                    <MDButton onClick={handleDeleteClick} autoFocus>
                                        Agree
                                    </MDButton>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={fileModalOpen}
                                onClose={handleFileModalClose}
                                aria-labelledby="file-upload-dialog-title"
                                aria-describedby="file-upload-dialog-description"
                            >
                                <DialogTitle id="file-upload-dialog-title">
                                    Upload File
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="file-upload-dialog-description">
                                        Please select a file to upload.
                                    </DialogContentText>
                                    <Input
                                        type="file"
                                        accept=".xlsx"
                                        margin="dense"
                                        onChange={handleFileChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <MDButton onClick={handleFileModalClose}>Cancel</MDButton>
                                    <MDButton
                                        onClick={handleFileUpload}
                                        disabled={!selectedFile}
                                        autoFocus
                                    >
                                        Upload
                                    </MDButton>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}
