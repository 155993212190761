import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import BottomSection from "./BottomSection";

const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
        width: "100%",
    },
};

const CreateCustomForm = () => {
    const [rowDataFromBottomSection, setRowDataFromBottomSection] = useState(null);
    const navigate = useNavigate();
    const [file, setFile] = useState();
    const [checked, setChecked] = useState(true);

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };
    const getValues = () => {
        return {
            title: "",
            desc: "",
            status: "",
        };
    };
    const [values, setValues] = useState(getValues);
    const handleChange = (event) => {
        setValues((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value,
        }));
    };
    const handleFile = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const { title, desc } = values;
        // Check if any field is empty
        if (!title || !desc || !file) {
            //   enqueueSnackbar("Please fill in all fields", { variant: "error" });
            alert('please fill in all field')
            return;
        }
        console.log("Row Data from BottomSection:", rowDataFromBottomSection);
        console.log("upper Values:", values);
        console.log(file)
        // createform(values);
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Custom Form Creation
                                </MDTypography>
                            </MDBox>

                            <MDBox p={3} component="form" role="form">
                                <MDBox p={1}>
                                    <InputLabel id="demo-multiple-name-label3">Banner Image</InputLabel>
                                    <MDInput type="file" name="file" onChange={handleFile} fullWidth />
                                </MDBox>
                                <MDBox p={1}>
                                    <MDInput
                                        type="text"
                                        label="Title"
                                        value={values.title}
                                        name="title"
                                        margin="dense"
                                        fullWidth={true}
                                        onChange={handleChange}
                                    />
                                </MDBox>
                                <MDBox p={1}>
                                    <MDInput
                                        type="text"
                                        label="Description"
                                        value={values.desc}
                                        name="desc"
                                        margin="dense"
                                        fullWidth={true}
                                        onChange={handleChange}
                                    />
                                </MDBox>
                                <MDBox p={1}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                // disabled={isDisabled}
                                                checked={checked}
                                                onChange={handleSwitchChange}
                                                // value={values.status}
                                                // name="status"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label="Status"
                                    />
                                </MDBox>

                                <BottomSection setRowDataFromBottomSection={setRowDataFromBottomSection} />

                                <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        onClick={handleSubmit}
                                    >
                                        Create
                                    </MDButton>
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
};

export default CreateCustomForm;
