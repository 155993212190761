/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import logo from "../../../assets/images/Logo.png";
import axios from "axios";
import { useState } from "react";

function Basic() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [cred, setCred] = useState({ email: "", password: "" });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCred({
      ...cred,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    try {
      const apiUrl = "https://api.scitechpark.org.in/user/sign-in";
      const response = await axios.post(apiUrl, cred);
      // console.log(response);
      if (response.data.success) {
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("User_role", "admin");
        enqueueSnackbar("Logged in successfully.", { variant: "success" });
        navigate("/dashboard");
        window.location.reload();
      } else {
        enqueueSnackbar("Login failed, something went wrong.", {
          variant: "error",
        });
        console.log("Login failed, something went wrong.");
      }
    } catch (error) {
      enqueueSnackbar(
        "Login failed, Check your credentials or User not found.",
        { variant: "error" }
      );
      console.log(error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox mb={4}>
            <MDBox component="img" src={logo} alt="Brand" width="20rem" />
          </MDBox>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={handleChange}
                name="email"
                type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={handleChange}
                name="password"
                type="password" label="Password" fullWidth />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleLogin}>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
