import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    contacts: [],
    contactById: null
};

const ContactReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_CONTACT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_CONTACT_SUCCESS:
            return { ...state, loading: false, contacts: payload };
        case actionTypes.GET_CONTACT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_CONTACT_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_CONTACT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                contactById: payload,
            };
        case actionTypes.GET_CONTACT_BY_ID_ERROR:
            return { ...state, loading: false, error: true };


        default:
            return state;
    }
};

export default ContactReducer;
