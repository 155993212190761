import React, { useEffect } from 'react'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from 'components/MDButton';
import { Dialog, DialogActions, DialogContent, DialogTitle,  } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GetContacts } from '../../Redux/action';
import { useState } from 'react';
import { useSnackbar } from 'notistack';



export default function Events() {

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const Author = ({ name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1} >
            {/* <MDAvatar src={image} name={name} size="sm" /> */}
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [selectedQuery, setSelectedQuery] = useState(null);
    const { contacts, loading } = useSelector((store) => store.ContactReducer);
    const contactsData = contacts || []
    console.log('contactsData', contacts);

    const handleClickOpen = (query) => {
        setSelectedQuery(query);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedQuery(null);
    };



    const columns = [
        { Header: "author", accessor: "author", width: "25%", align: "left" },
        { Header: "query", accessor: "query", align: "left" },
        { Header: "received", accessor: "received", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
    ];

    const rows = contactsData && contactsData?.map((data) => ({
        author: (
            <Author
                // image={`${docUrl}${data?.Images}`}
                name={`${data?.Name}`}
                email={data.Email}
            />
        ),
        query: (
            <MDTypography
                component="a"
                href="#" variant="caption" color="text" fontWeight="medium">
                {truncateText(data?.Query, 70)}
            </MDTypography>
        ),

        received: (
            <MDTypography
                component="a"
                href="#" variant="caption" color="text" fontWeight="medium">
                {data?.Created_at?.slice(0, 10)}
            </MDTypography>
        ),
        action: (
            <MDBadge
                color={"dark"}
                badgeContent={"View"}
                sx={{ cursor: "pointer" }}
                fontWeight="medium"
                onClick={() => {
                    if (data?.Id) handleClickOpen(data);
                }}
            />
        ),
    }));

    const handleGetUser = async () => {
        try {
            await dispatch(GetContacts());
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        handleGetUser();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    User Queries
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                {contactsData
                                    && !loading
                                    ?
                                    (
                                        contactsData?.length > 0 ?
                                            <DataTable
                                                table={{ columns, rows }}
                                                isSorted={false}
                                                entriesPerPage={false}
                                                showTotalEntries={false}
                                                noEndBorder
                                            />
                                            : (
                                                <Grid
                                                    style={{
                                                        display: "grid",
                                                        placeItems: "center",
                                                        margin: "15.7%",
                                                    }}
                                                >
                                                    <img
                                                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                                                        width={"80px"}
                                                        height={"80px"}
                                                        alt=""
                                                    />
                                                    <MDTypography fontSize="12px">
                                                        No query found.
                                                    </MDTypography>
                                                </Grid>
                                            )
                                    ) : (
                                        <span style={{ fontSize: "15px", margin: "2rem" }}>
                                            Loading...
                                        </span>
                                    )}
                            </MDBox>


                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="query-dialog-title"
                                aria-describedby="query-dialog-description"
                            >
                                <DialogTitle id="query-dialog-title">Query Details</DialogTitle>
                                <DialogContent>
                                    {selectedQuery && (
                                        <MDBox>
                                            <MDTypography variant="h6">
                                                Name: {selectedQuery?.Name}
                                            </MDTypography>
                                            <MDTypography variant="h6">
                                                Email: {selectedQuery?.Email}
                                            </MDTypography>
                                            <MDTypography variant="h6">
                                                Phone Number: {selectedQuery?.Phone_no}
                                            </MDTypography>
                                            <MDTypography variant="h6">
                                                Query: {selectedQuery?.Query}
                                            </MDTypography>
                                            <MDTypography variant="h6">
                                                Received: {selectedQuery?.Created_at?.slice(0, 10)}
                                            </MDTypography>
                                        </MDBox>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <MDButton onClick={handleClose}>Close</MDButton>
                                </DialogActions>
                            </Dialog>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}