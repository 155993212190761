import {
    applyMiddleware,
    combineReducers,
    legacy_createStore,
    compose,
} from "redux";
import { thunk } from "redux-thunk";
import EventReducer from "./eventReducer";
import GrowthLabReducer from "./growthLabReducer";
import BannerReducer from "./bannerReducer";
import NewsReducer from "./newsReducer";
import AssociationReducer from "./associationReducer";
import ContactReducer from "./contactReducer";
import AwardsReducer from "./awardsReducer";
import AnnouncementReducer from "./announcementReducer";

const rootReducer = combineReducers({
    BannerReducer,
    EventReducer,
    GrowthLabReducer,
    NewsReducer,
    AssociationReducer,
    ContactReducer,
    AwardsReducer,
    AnnouncementReducer,
});

export const store = legacy_createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
);
