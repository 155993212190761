import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    growthLabs: [],
    growthLabById: null
};

const GrowthLabReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_GROWTH_LAB_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_GROWTH_LAB_SUCCESS:
            return { ...state, loading: false, growthLabs: payload };
        case actionTypes.GET_GROWTH_LAB_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_GROWTH_LAB_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_GROWTH_LAB_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                growthLabById: payload,
            };
        case actionTypes.GET_GROWTH_LAB_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_GROWTH_LAB_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_GROWTH_LAB_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                growthLabs: [payload, ...state.growthLabs],
            };
        case actionTypes.ADD_GROWTH_LAB_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_GROWTH_LAB_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_GROWTH_LAB_SUCCESS:
            return { ...state, loading: false, growthLabById: payload };
        case actionTypes.UPDATE_GROWTH_LAB_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.DELETE_GROWTH_LAB_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.DELETE_GROWTH_LAB_SUCCESS:
            return {
                ...state,
                loading: false,
                growthLabs: state.growthLabs.filter(growthLab => growthLab.id !== payload),
            };
        case actionTypes.DELETE_GROWTH_LAB_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default GrowthLabReducer;
