import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    announcements: [],
    announcementById: null,
};

const AnnouncementReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_ANNOUNCEMENT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_ANNOUNCEMENT_SUCCESS:
            return { ...state, loading: false, announcements: payload };
        case actionTypes.GET_ANNOUNCEMENT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_ANNOUNCEMENT_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_ANNOUNCEMENT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                announcementById: payload,
            };
        case actionTypes.GET_ANNOUNCEMENT_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_ANNOUNCEMENT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_ANNOUNCEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                announcements: [payload, ...state.announcements],
            };
        case actionTypes.ADD_ANNOUNCEMENT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_ANNOUNCEMENT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS:
            return { ...state, loading: false, announcementById: payload };
        case actionTypes.UPDATE_ANNOUNCEMENT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.DELETE_ANNOUNCEMENT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.DELETE_ANNOUNCEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                announcements: state.announcements.filter(
                    (announcement) => announcement.Announcement_id !== payload
                ),
            };
        case actionTypes.DELETE_ANNOUNCEMENT_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default AnnouncementReducer;
