import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    associations: [],
    associationById: null,
};

const AssociationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_ASSOCIATION_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_ASSOCIATION_SUCCESS:
            return { ...state, loading: false, associations: payload };
        case actionTypes.GET_ASSOCIATION_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_ASSOCIATION_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_ASSOCIATION_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                associationById: payload,
            };
        case actionTypes.GET_ASSOCIATION_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_ASSOCIATION_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_ASSOCIATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                associations: [payload, ...state?.associations],
            };
        case actionTypes.ADD_ASSOCIATION_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_ASSOCIATION_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_ASSOCIATION_SUCCESS:
            return { ...state, loading: false, associationById: payload };
        case actionTypes.UPDATE_ASSOCIATION_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.DELETE_ASSOCIATION_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.DELETE_ASSOCIATION_SUCCESS:
            return {
                ...state,
                loading: false,
                associations: state.associations.filter(
                    (association) => association.id !== payload
                ),
            };
        case actionTypes.DELETE_ASSOCIATION_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default AssociationReducer;
