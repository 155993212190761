import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    events: [],
    eventById: null
};

const EventReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_EVENT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_EVENT_SUCCESS:
            return { ...state, loading: false, events: payload };
        case actionTypes.GET_EVENT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_EVENT_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_EVENT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                eventById: payload,
            };
        case actionTypes.GET_EVENT_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_EVENT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                events: [payload, ...state?.events],
            };
        case actionTypes.ADD_EVENT_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_EVENT_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_EVENT_SUCCESS:
            return { ...state, loading: false, eventById: payload };
        case actionTypes.UPDATE_EVENT_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default EventReducer;
