import { actionTypes } from "./action.types";

const initialState = {
    loading: false,
    error: false,
    news: [],
    newsById: null
};

const NewsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_NEWS_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_NEWS_SUCCESS:
            return { ...state, loading: false, news: payload };
        case actionTypes.GET_NEWS_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.GET_NEWS_BY_ID_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.GET_NEWS_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                newsById: payload,
            };
        case actionTypes.GET_NEWS_BY_ID_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.ADD_NEWS_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.ADD_NEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                news: [payload, ...state.news],
            };
        case actionTypes.ADD_NEWS_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.UPDATE_NEWS_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.UPDATE_NEWS_SUCCESS:
            return { ...state, loading: false, newsById: payload };
        case actionTypes.UPDATE_NEWS_ERROR:
            return { ...state, loading: false, error: true };

        case actionTypes.DELETE_NEWS_LOADING:
            return { ...state, loading: true, error: false };
        case actionTypes.DELETE_NEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                news: state.news.filter(newsItem => newsItem.id !== payload),
            };
        case actionTypes.DELETE_NEWS_ERROR:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
};

export default NewsReducer;
