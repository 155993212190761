import * as React from "react";
import {
    FormControl,
    Grid,
    ImageList,
    ImageListItem,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { AddAwards, GetAwardById, UpdateAwards } from "../../../Redux/action";
import DeleteIcon from "@mui/icons-material/Delete";

const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
        width: "100%",
    },
};

const AwardsRecognitionForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const type = location?.state?.type;
    const idFromLocation = location?.state?.id;
    const { awardById } = useSelector((store) => store.AwardsReducer);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const [imagePreview, setImagePreview] = useState("");
    const [fileInputs, setFileInputs] = useState({
        files: null,
    });

    const payloadObj = {
        Name: "",
        Award_date: "",
        Description: "",
        Received_by: "",
        User_role: "admin",
    };

    const [payload, setPayload] = useState(
        idFromLocation
            ? {
                ...awardById,
                Images: Array.isArray(awardById?.Images) ? awardById.Images : [],
                files: awardById?.files || [],
            }
            : payloadObj
    );

    const [receivedBy, setReceivedBy] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        const filesValue = e.target.files?.[0];
        if (name === "files") {
            const temp = URL.createObjectURL(filesValue);
            if (temp) {
                setImagePreview(temp);
            }
            setFileInputs({ ...fileInputs, [name]: filesValue });
        } else {
            setPayload({ ...payload, [name]: value });
        }
    };

    const handleSelectChange = (e) => {
        const { value } = e.target;
        setReceivedBy(value);
        if (value === "Science and Technology Park") {
            setPayload({ ...payload, Received_by: value });
        } else {
            setPayload({ ...payload, Received_by: "" });
        }
    };

    const handleRemoveImages = (i) => {
        const updatedImages = payload?.Images?.filter((_, index) => index !== i);
        setPayload({ ...payload, Images: updatedImages || [] });
    };

    const handleResponseAddAwards = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Data added successfully.`, { variant: "success" });
            navigate("/awards-and-recognition");
        } else {
            enqueueSnackbar(
                `${res?.data?.message || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleResponseUpdateAwards = (success = false, res) => {
        if (success) {
            enqueueSnackbar(`Data updated successfully.`, { variant: "success" });
            navigate("/awards-and-recognition");
        } else {
            enqueueSnackbar(
                `${res?.data?.error || "Something went wrong, Please check all fields."
                }`,
                { variant: "error" }
            );
        }
    };

    const handleClick = () => {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (key === "Images") {
                var stringifiedImg = JSON.stringify(value);
                formData.append(key, stringifiedImg);
            } else {
                formData.append(key, value);
            }
        });

        Object.entries(fileInputs).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (type === "add") {
            dispatch(AddAwards(formData, handleResponseAddAwards));
        } else {
            dispatch(
                UpdateAwards(idFromLocation, formData, handleResponseUpdateAwards)
            );
        }
    };

    useEffect(() => {
        if (idFromLocation) {
            dispatch(GetAwardById(idFromLocation));
        }
    }, [idFromLocation, dispatch]);

    useEffect(() => {
        if (idFromLocation && awardById) {
            const updatedPayload = {
                ...awardById,
                Images:
                    typeof awardById?.Images === "string"
                        ? JSON.parse(awardById?.Images)
                        : awardById?.Images,
            };
            setPayload(updatedPayload);
        }
    }, [awardById, idFromLocation]);

    useEffect(() => {
        if (type === "add") {
            setPayload(payloadObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={3}
                p={2}
                mb={1}
                textAlign="center"
            >
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                    Awards and Recognition Management
                </MDTypography>
            </MDBox>
            <MDBox
                mx={2}
                mt={3}
                py={3}
                p={4}
                mb={2}
                borderRadius="lg"
                variant="gradient"
                coloredShadow="info"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    gap={3}
                    pb={2}
                >
                    <MDInput
                        required
                        type="text"
                        label="Name"
                        name="Name"
                        value={payload?.Name || ""}
                        margin="dense"
                        fullWidth
                        onChange={handleChange}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="received-by-label">Award received by</InputLabel>
                        <Select
                            style={{ height: "45px" }}
                            labelId="received-by-label"
                            value={receivedBy}
                            label="Award received by"
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="Science and Technology Park">
                                Science and Technology Park
                            </MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                    {receivedBy === "Other" && (
                        <MDInput
                            required
                            type="text"
                            label="Specify"
                            name="Received_by"
                            value={payload?.Received_by || ""}
                            margin="dense"
                            fullWidth
                            onChange={handleChange}
                        />
                    )}
                    <TextField
                        required
                        label="Description"
                        name="Description"
                        value={payload?.Description || ""}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                    />
                    <MDInput
                        required
                        type="date"
                        // label=""
                        name="Award_date"
                        value={payload?.Award_date?.slice(0, 10) || ""}
                        fullWidth
                        onChange={handleChange}
                    />

                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        gap={3}
                        // pt={2}
                        fullWidth
                    >
                        <MDTypography
                            variant="h6"
                            sx={{ fontSize: "14px", fontWeight: "400" }}
                        >
                            Upload Awards and Recognition Image
                        </MDTypography>
                        <MDInput
                            type="file"
                            name="files"
                            margin="dense"
                            onChange={handleChange}
                        />
                        {imagePreview ? (
                            <img
                                src={`${imagePreview}`}
                                alt="Awards and Recognition images"
                                style={{
                                    width: "50%",
                                    aspectRatio: "3/2",
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            <MDTypography
                                variant="h6"
                                sx={{ fontSize: "14px", fontWeight: "400" }}
                            >
                                No latest images selected yet.
                            </MDTypography>
                        )}

                        {type === "edit" && payload?.Images ? (
                            <ImageList
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginTop: "2rem",
                                    padding: "5px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    "@media (max-width: 961px)": {
                                        justifyContent: "center",
                                    },
                                }}
                                rowHeight={164}
                            >
                                {type === "edit" &&
                                    payload?.Images &&
                                    payload?.Images.map((file, index) => (
                                        <ImageListItem key={index} style={{ width: "200px" }}>
                                            <img
                                                src={`${docUrl}${file}`}
                                                alt={"Awards and Recognition Images"}
                                                style={{
                                                    width: "100%",
                                                    aspectRatio: "3/2",
                                                    objectFit: "contain",
                                                }}
                                            />
                                            <MDButton
                                                component="button"
                                                variant="body2"
                                                onClick={() => handleRemoveImages(index)}
                                                fullWidth
                                                style={{ marginTop: "6px" }}
                                            >
                                                Remove the photo <DeleteIcon />
                                            </MDButton>
                                        </ImageListItem>
                                    ))}
                            </ImageList>
                        ) : null}
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
                <MDButton variant="gradient" color="info" onClick={handleClick}>
                    {type === "add"
                        ? "Add Awards and Recognition"
                        : "Update Awards and Recognition"}
                </MDButton>
            </Grid>
        </DashboardLayout>
    );
};

export default AwardsRecognitionForm;
