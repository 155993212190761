import React, { useState, useEffect } from "react";
import "./BottomSection.css";
import Select from "react-select";
// import Select from "@mui/material/Select";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";

function BottomSection({ setRowDataFromBottomSection }) {
    const [data, setData] = useState([]);
    const [project, setProject] = useState(null);
    const [rowData, setRowData] = useState({
        inputNo: 1,
        inputLabel: "",
        inputPlaceholder: "",
        inputtype: "",
        project: "",
        action: "",
    });
    const [editIndex, setEditIndex] = useState(null);

    const handleChange = (event) => {
        setRowData({ ...rowData, [event.target.name]: event.target.value });
    };
    const handleProjectChange = (selectedOption) => {
        setProject(selectedOption); // Update the selected project option
        setRowData({ ...rowData, project: selectedOption.value }); // Update the rowData with the selected project value
    };
    const handleCreateRow = () => {
        // Check if any field is empty
        if (!rowData.inputNo || !rowData.project || !rowData.inputLabel || !rowData.inputPlaceholder) {
            alert("Please fill in all fields before adding a new input.");
            return; // Exit function if any field is empty
        }

        if (editIndex === null) {
            setData([...data, rowData]);
            setRowData(prevData => ({
                project: "",
                inputLabel: "",
                inputPlaceholder: "",
                inputtype: "",
                action: "",
                inputNo: prevData.inputNo + 1
            }));
        } else {
            const updatedData = [...data];
            updatedData[editIndex] = rowData;
            setData(updatedData);
            setEditIndex(null);
            setRowData(prevData => ({
                project: "",
                inputLabel: "",
                inputPlaceholder: "",
                inputtype: "",
                action: "",
                inputNo: prevData.inputNo + 1
            }));
        }
        setProject(null);
    };
    useEffect(() => {
        setRowDataFromBottomSection(data);
    }, [data])
    
    const handleEditRow = (index) => {
        // setRowData(data[index]);
        setRowData({
            ...data[index],
            project: data[index].project // Set the project value in rowData
        });
        console.log(data[index])
        setProject(data[index].project)
        // setProject(data[index]); // Update the selected project option
        // setRowData({ ...rowData, project: data[index].project }); 
        setEditIndex(index);
    };

    const handleDeleteRow = (index) => {
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
    };

    const options = [
        { value: "Text", label: "Text" },
        { value: "Long Text", label: "Long Text" },
        { value: "Number", label: "Number" },
        { value: "Date", label: "Date" },
        { value: "Radio", label: "Radio" },
        { value: "DropDown", label: "DropDown" },
        { value: "Checkboxes", label: "Checkboxes" },
    ];

    return (
        <div>
            <MDTypography variant="h2" style={{ marginBottom: '1.5rem' }}>
                Questionnaire
            </MDTypography>
            <table>
                <thead>
                    <tr>
                        {/* <th>Input No</th> */}
                        <th>Input Type</th>
                        <th>Input Label</th>
                        <th>Input Placeholder</th>
                        {rowData.inputNo !== 1 && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr>
                            {/* <td>
                                <input
                                    type="number"
                                    value={row.inputNo}
                                    style={{
                                        marginLeft: "30px",
                                        padding: "7px",
                                        width: "160px",
                                        border: "1px solid grey",
                                        borderRadius: "4px",
                                        fontWeight: "bold",
                                    }}
                                />
                            </td> */}
                            <td><input value={row.project} style={{ marginLeft: "30px", padding: "8px", border: "1px solid grey", borderRadius: "4px", fontWeight: "bold" }} disabled /></td>
                            <td><input value={row.inputLabel} style={{ marginLeft: "30px", padding: "8px", border: "1px solid grey", borderRadius: "4px", fontWeight: "bold" }} disabled /></td>
                            <td><input value={row.inputPlaceholder} style={{ marginLeft: "30px", padding: "8px", border: "1px solid grey", borderRadius: "4px", fontWeight: "bold" }} disabled /></td>

                            <td>
                                <button onClick={() => handleEditRow(index)} style={{ cursor: "pointer", background: "none", border: "none", fontSize: "20px" }}>
                                    <EditIcon opacity="0.8" />
                                </button>

                                <button onClick={() => handleDeleteRow(index)} style={{ cursor: "pointer", marginLeft: "10px", border: "none", background: "none", fontSize: "20px" }}>
                                    <DeleteIcon />
                                </button>
                            </td>
                        </tr>
                    ))}
                    <tr className="formData">
                        {/* <td>
                            <input
                                className="INVOICENO"
                                type="text"
                                name="inputNo"
                                value={rowData.inputNo}
                                placeholder="Invoice No"
                                onChange={handleChange}
                                required
                                disabled
                            />
                        </td> */}
                        <td style={{ width: "200px" }}>
                            <Select
                                className="PROJECT"
                                name="project"
                                options={options}
                                value={project}
                                // defaultValue={project}
                                placeholder="Input Type"
                                onChange={(selectedOption) =>
                                    handleProjectChange(selectedOption)
                                }
                                required
                            />
                           
                        </td>
                        {/* <td>
                            <center>
                             
                            </center>
                        </td> */}
                        <td>
                            {/* <input
                                className="DESCRIPTION"
                                type="text"
                                name="inputLabel"
                                value={rowData.inputLabel}
                                placeholder="Input Label"
                                onChange={handleChange}
                                required
                            /> */}

                            <MDBox p={1}>
                                <MDInput
                                    type="text"
                                    label="Input Label"
                                    value={rowData.inputLabel}
                                    name="inputLabel"
                                    placeholder="Input Label"
                                    margin="dense"
                                    fullWidth={true}
                                    onChange={handleChange}
                                    required
                                />
                            </MDBox>
                        </td>
                        <td>
                            {/* <input
                                className="DESCRIPTION"
                                type="text"
                                name="inputPlaceholder"
                                value={rowData.inputPlaceholder}
                                placeholder="Input Placeholder"
                                onChange={handleChange}
                                required
                            /> */}
                            <MDBox p={1}>
                                <MDInput
                                    type="text"
                                    name="inputPlaceholder"
                                    value={rowData.inputPlaceholder}
                                    placeholder="Input Placeholder"
                                    label="Input Placeholder"
                                    margin="dense"
                                    fullWidth={true}
                                    onChange={handleChange}
                                    required
                                />
                            </MDBox>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="btn">
                <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleCreateRow}
                >
                    {editIndex === null ? "Add Input" : "Update"}
                </MDButton>
            </div>
        </div>
    );
}

export default BottomSection;
