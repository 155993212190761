import React from 'react'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from 'components/MDButton';
import { Tooltip } from '@mui/material';
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useNavigate } from 'react-router-dom';


export default function CustomForms() {

    const navigate = useNavigate();

    const Author = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
            <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
    );

    const columns = [
        { Header: "author", accessor: "author", width: "45%", align: "left" },
        { Header: "function", accessor: "function", align: "left" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "employed", accessor: "employed", align: "center" },
        // { Header: "action", accessor: "action", align: "center" },
    ];

    const rows = [
        {
            author: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
            function: <Job title="Manager" description="Organization" />,
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
                </MDBox>
            ),
            employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    23/04/18
                </MDTypography>
            ),
            // action: (
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //         Edit
            //     </MDTypography>
            // ),
        },
        {
            author: <Author image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,
            function: <Job title="Programator" description="Developer" />,
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
                </MDBox>
            ),
            employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    11/01/19
                </MDTypography>
            ),
            // action: (
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //         Edit
            //     </MDTypography>
            // ),
        },
        {
            author: <Author image={team4} name="Laurent Perrier" email="laurent@creative-tim.com" />,
            function: <Job title="Executive" description="Projects" />,
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
                </MDBox>
            ),
            employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    19/09/17
                </MDTypography>
            ),
            // action: (
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //         Edit
            //     </MDTypography>
            // ),
        },
        {
            author: <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
            function: <Job title="Programator" description="Developer" />,
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
                </MDBox>
            ),
            employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    24/12/08
                </MDTypography>
            ),
            // action: (
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //         Edit
            //     </MDTypography>
            // ),
        },
        {
            author: <Author image={team3} name="Richard Gran" email="richard@creative-tim.com" />,
            function: <Job title="Manager" description="Executive" />,
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
                </MDBox>
            ),
            employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    04/10/21
                </MDTypography>
            ),
            // action: (
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //         Edit
            //     </MDTypography>
            // ),
        },
        {
            author: <Author image={team4} name="Miriam Eric" email="miriam@creative-tim.com" />,
            function: <Job title="Programator" description="Developer" />,
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
                </MDBox>
            ),
            employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    14/09/20
                </MDTypography>
            ),
            // action: (
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //         Edit
            //     </MDTypography>
            // ),
        },
    ]


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Custom Forms
                                </MDTypography>
                                <MDBox className="ticket_btnsHeaderCont">
                                    <Tooltip title="Add a new form">
                                        <MDButton
                                            onClick={() => {
                                                navigate("/customforms/create", { state: { type: "add" } });
                                            }}
                                            variant="outlined"
                                            color="white"
                                            startIcon={<PersonAddAlt1Icon />}
                                        >
                                            Creation of Custom Form
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}