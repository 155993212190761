export const actionTypes = {
    // EVENTS
    GET_EVENT_SUCCESS: "getEventSuccess",
    GET_EVENT_LOADING: "getEventLoading",
    GET_EVENT_ERROR: "getEventError",

    GET_EVENT_BY_ID_SUCCESS: "getEventByIdSuccess",
    GET_EVENT_BY_ID_LOADING: "getEventByIdLoading",
    GET_EVENT_BY_ID_ERROR: "getEventByIdError",

    ADD_EVENT_SUCCESS: "addEventSuccess",
    ADD_EVENT_LOADING: "addEventLoading",
    ADD_EVENT_ERROR: "addEventError",

    UPDATE_EVENT_SUCCESS: "updateEventSuccess",
    UPDATE_EVENT_LOADING: "updateEventLoading",
    UPDATE_EVENT_ERROR: "updateEventError",

    DELETE_EVENT_SUCCESS: "deleteEventSuccess",
    DELETE_EVENT_LOADING: "deleteEventLoading",
    DELETE_EVENT_ERROR: "deleteEventError",

    // GROWTH LAB
    GET_GROWTH_LAB_SUCCESS: "getGrowthLabSuccess",
    GET_GROWTH_LAB_LOADING: "getGrowthLabLoading",
    GET_GROWTH_LAB_ERROR: "getGrowthLabError",

    GET_GROWTH_LAB_BY_ID_SUCCESS: "getGrowthLabByIdSuccess",
    GET_GROWTH_LAB_BY_ID_LOADING: "getGrowthLabByIdLoading",
    GET_GROWTH_LAB_BY_ID_ERROR: "getGrowthLabByIdError",

    ADD_GROWTH_LAB_SUCCESS: "addGrowthLabSuccess",
    ADD_GROWTH_LAB_LOADING: "addGrowthLabLoading",
    ADD_GROWTH_LAB_ERROR: "addGrowthLabError",

    UPDATE_GROWTH_LAB_SUCCESS: "updateGrowthLabSuccess",
    UPDATE_GROWTH_LAB_LOADING: "updateGrowthLabLoading",
    UPDATE_GROWTH_LAB_ERROR: "updateGrowthLabError",

    DELETE_GROWTH_LAB_SUCCESS: "deleteGrowthLabSuccess",
    DELETE_GROWTH_LAB_LOADING: "deleteGrowthLabLoading",
    DELETE_GROWTH_LAB_ERROR: "deleteGrowthLabError",

    // Banners
    GET_BANNER_SUCCESS: "getBannerSuccess",
    GET_BANNER_LOADING: "getBannerLoading",
    GET_BANNER_ERROR: "getBannerError",

    GET_BANNER_BY_ID_SUCCESS: "getBannerByIdSuccess",
    GET_BANNER_BY_ID_LOADING: "getBannerByIdLoading",
    GET_BANNER_BY_ID_ERROR: "getBannerByIdError",

    ADD_BANNER_SUCCESS: "addBannerSuccess",
    ADD_BANNER_LOADING: "addBannerLoading",
    ADD_BANNER_ERROR: "addBannerError",

    UPDATE_BANNER_SUCCESS: "updateBannerSuccess",
    UPDATE_BANNER_LOADING: "updateBannerLoading",
    UPDATE_BANNER_ERROR: "updateBannerError",

    DELETE_BANNER_SUCCESS: "deleteBannerSuccess",
    DELETE_BANNER_LOADING: "deleteBannerLoading",
    DELETE_BANNER_ERROR: "deleteBannerError",

    // NEWS
    GET_NEWS_SUCCESS: "getNewsSuccess",
    GET_NEWS_LOADING: "getNewsLoading",
    GET_NEWS_ERROR: "getNewsError",

    GET_NEWS_BY_ID_SUCCESS: "getNewsByIdSuccess",
    GET_NEWS_BY_ID_LOADING: "getNewsByIdLoading",
    GET_NEWS_BY_ID_ERROR: "getNewsByIdError",

    ADD_NEWS_SUCCESS: "addNewsSuccess",
    ADD_NEWS_LOADING: "addNewsLoading",
    ADD_NEWS_ERROR: "addNewsError",

    UPDATE_NEWS_SUCCESS: "updateNewsSuccess",
    UPDATE_NEWS_LOADING: "updateNewsLoading",
    UPDATE_NEWS_ERROR: "updateNewsError",

    DELETE_NEWS_SUCCESS: "deleteNewsSuccess",
    DELETE_NEWS_LOADING: "deleteNewsLoading",
    DELETE_NEWS_ERROR: "deleteNewsError",

    // ASSOCIATION
    GET_ASSOCIATION_SUCCESS: "getAssociationSuccess",
    GET_ASSOCIATION_LOADING: "getAssociationLoading",
    GET_ASSOCIATION_ERROR: "getAssociationError",

    GET_ASSOCIATION_BY_ID_SUCCESS: "getAssociationByIdSuccess",
    GET_ASSOCIATION_BY_ID_LOADING: "getAssociationByIdLoading",
    GET_ASSOCIATION_BY_ID_ERROR: "getAssociationByIdError",

    ADD_ASSOCIATION_SUCCESS: "addAssociationSuccess",
    ADD_ASSOCIATION_LOADING: "addAssociationLoading",
    ADD_ASSOCIATION_ERROR: "addAssociationError",

    UPDATE_ASSOCIATION_SUCCESS: "updateAssociationSuccess",
    UPDATE_ASSOCIATION_LOADING: "updateAssociationLoading",
    UPDATE_ASSOCIATION_ERROR: "updateAssociationError",

    DELETE_ASSOCIATION_SUCCESS: "deleteAssociationSuccess",
    DELETE_ASSOCIATION_LOADING: "deleteAssociationLoading",
    DELETE_ASSOCIATION_ERROR: "deleteAssociationError",

    // CONTACT
    GET_CONTACT_SUCCESS: "getCONTACTSuccess",
    GET_CONTACT_LOADING: "getCONTACTLoading",
    GET_CONTACT_ERROR: "getCONTACTError",

    GET_CONTACT_BY_ID_SUCCESS: "getCONTACTByIdSuccess",
    GET_CONTACT_BY_ID_LOADING: "getCONTACTByIdLoading",
    GET_CONTACT_BY_ID_ERROR: "getCONTACTByIdError",

    // Awards
    GET_AWARD_SUCCESS: "getAwardSuccess",
    GET_AWARD_LOADING: "getAwardLoading",
    GET_AWARD_ERROR: "getAwardError",

    GET_AWARD_BY_ID_SUCCESS: "getAwardByIdSuccess",
    GET_AWARD_BY_ID_LOADING: "getAwardByIdLoading",
    GET_AWARD_BY_ID_ERROR: "getAwardByIdError",

    ADD_AWARD_SUCCESS: "addAwardSuccess",
    ADD_AWARD_LOADING: "addAwardLoading",
    ADD_AWARD_ERROR: "addAwardError",

    UPDATE_AWARD_SUCCESS: "updateAwardSuccess",
    UPDATE_AWARD_LOADING: "updateAwardLoading",
    UPDATE_AWARD_ERROR: "updateAwardError",

    DELETE_AWARD_SUCCESS: "deleteAwardSuccess",
    DELETE_AWARD_LOADING: "deleteAwardLoading",
    DELETE_AWARD_ERROR: "deleteAwardError",

    // Announcements
    GET_ANNOUNCEMENT_SUCCESS: "getAnnouncementSuccess",
    GET_ANNOUNCEMENT_LOADING: "getAnnouncementLoading",
    GET_ANNOUNCEMENT_ERROR: "getAnnouncementError",

    GET_ANNOUNCEMENT_BY_ID_SUCCESS: "getAnnouncementByIdSuccess",
    GET_ANNOUNCEMENT_BY_ID_LOADING: "getAnnouncementByIdLoading",
    GET_ANNOUNCEMENT_BY_ID_ERROR: "getAnnouncementByIdError",

    ADD_ANNOUNCEMENT_SUCCESS: "addAnnouncementSuccess",
    ADD_ANNOUNCEMENT_LOADING: "addAnnouncementLoading",
    ADD_ANNOUNCEMENT_ERROR: "addAnnouncementError",

    UPDATE_ANNOUNCEMENT_SUCCESS: "updateAnnouncementSuccess",
    UPDATE_ANNOUNCEMENT_LOADING: "updateAnnouncementLoading",
    UPDATE_ANNOUNCEMENT_ERROR: "updateAnnouncementError",

    DELETE_ANNOUNCEMENT_SUCCESS: "deleteAnnouncementSuccess",
    DELETE_ANNOUNCEMENT_LOADING: "deleteAnnouncementLoading",
    DELETE_ANNOUNCEMENT_ERROR: "deleteAnnouncementError",

};
