import * as React from "react";
import {
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { AddAnnouncement, GetAnnouncementById, UpdateAnnouncement } from "../../../Redux/action";
import DeleteIcon from "@mui/icons-material/Delete";

const widthStyle = {
  width: "45%",
  "@media (max-width: 600px)": {
    width: "100%",
  },
};

const AnnouncementForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const type = location?.state?.type;
  const idFromLocation = location?.state?.id;
  const { announcementById } = useSelector((store) => store.AnnouncementReducer);
  console.log("announcementById", announcementById);
  const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
  const [imagePreview, setImagePreview] = useState("");
  const [fileInputs, setFileInputs] = useState({
    files: null,
  });

  const payloadObj = {
    Title: "",
    Description: "",
    Add_date: "",
    Cta: "",
    Link: "",
    User_role: "admin",
  };

  const [payload, setPayload] = useState(
    idFromLocation
      ? {
        ...announcementById,
        Images: Array.isArray(announcementById?.Images) ? announcementById.Images : [],
        files: announcementById?.files || [],
      }
      : payloadObj
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const filesValue = e.target.files?.[0];
    if (name === "files") {
      const temp = URL.createObjectURL(filesValue);
      if (temp) {
        setImagePreview(temp);
      }
      setFileInputs({ ...fileInputs, [name]: filesValue });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  };

  const handleRemoveImages = (i) => {
    const updatedImages = payload?.Images?.filter((_, index) => index !== i);
    setPayload({ ...payload, Images: updatedImages || [] });
  };

  const handleResponseAddAnnouncement = (success = false, res) => {
    if (success) {
      enqueueSnackbar(`Announcement added successfully.`, { variant: "success" });
      navigate("/announcements");
    } else {
      enqueueSnackbar(
        `${res?.data?.message || "Something went wrong, please check all fields."}`,
        { variant: "error" }
      );
    }
  };

  const handleResponseUpdateAnnouncement = (success = false, res) => {
    if (success) {
      enqueueSnackbar(`Announcement updated successfully.`, { variant: "success" });
      navigate("/announcements");
    } else {
      enqueueSnackbar(
        `${res?.data?.error || "Something went wrong, please check all fields."}`,
        { variant: "error" }
      );
    }
  };

  const handleClick = () => {
    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
      if (key === "Images") {
        var stringifiedImg = JSON.stringify(value);
        formData.append(key, stringifiedImg);
      } else {
        formData.append(key, value);
      }
    });

    Object.entries(fileInputs).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (type === "add") {
      dispatch(AddAnnouncement(formData, handleResponseAddAnnouncement));
    } else {
      dispatch(
        UpdateAnnouncement(idFromLocation, formData, handleResponseUpdateAnnouncement)
      );
    }
  };

  useEffect(() => {
    if (idFromLocation) {
      dispatch(GetAnnouncementById(idFromLocation));
    }
  }, [idFromLocation, dispatch]);

  useEffect(() => {
    if (idFromLocation && announcementById) {
      const updatedPayload = {
        ...announcementById,
        Images:
          typeof announcementById?.Images === "string"
            ? JSON.parse(announcementById?.Images)
            : announcementById?.Images,
      };
      setPayload(updatedPayload);
    }
  }, [announcementById, idFromLocation]);

  useEffect(() => {
    if (type === "add") {
      setPayload(payloadObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
          Announcement Management
        </MDTypography>
      </MDBox>
      <MDBox
        mx={2}
        mt={3}
        py={3}
        p={4}
        mb={2}
        borderRadius="lg"
        variant="gradient"
        coloredShadow="info"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          gap={3}
          pb={2}
        >
          <MDInput
            required
            type="text"
            label="Title"
            name="Title"
            value={payload?.Title || ""}
            margin="dense"
            fullWidth
            onChange={handleChange}
          />

          <TextField
            required
            label="Description"
            name="Description"
            value={payload?.Description || ""}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            onChange={handleChange}
          />

          <MDInput
            required
            type="date"
            name="Add_date"
            value={payload?.Add_date?.slice(0, 10) || ""}
            fullWidth
            onChange={handleChange}
          />

          <MDInput
            required
            type="text"
            label="CTA"
            name="Cta"
            value={payload?.Cta || ""}
            fullWidth
            onChange={handleChange}
          />

          <MDInput
            required
            type="text"
            label="Link"
            name="Link"
            value={payload?.Link || ""}
            fullWidth
            onChange={handleChange}
          />

          <Grid container direction="column" gap={3} fullWidth>
            <MDTypography variant="h6" sx={{ fontSize: "14px", fontWeight: "400" }}>
              Upload Announcement Image
            </MDTypography>
            <MDInput
              type="file"
              name="files"
              margin="dense"
              onChange={handleChange}
            />
            {imagePreview ? (
              <img
                src={`${imagePreview}`}
                alt="Announcement images"
                style={{
                  width: "50%",
                  aspectRatio: "3/2",
                  objectFit: "contain",
                }}
              />
            ) : (
              <MDTypography variant="h6" sx={{ fontSize: "14px", fontWeight: "400" }}>
                No latest images selected yet.
              </MDTypography>
            )}

            {type === "edit" && payload?.Images ? (
              <ImageList
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "2rem",
                  padding: "5px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  "@media (max-width: 961px)": {
                    justifyContent: "center",
                  },
                }}
                rowHeight={164}
              >
                {type === "edit" &&
                  payload?.Images &&
                  payload?.Images.map((file, index) => (
                    <ImageListItem key={index} style={{ width: "200px" }}>
                      <img
                        src={`${docUrl}${file}`}
                        alt={"Announcement Images"}
                        style={{
                          width: "100%",
                          aspectRatio: "3/2",
                          objectFit: "contain",
                        }}
                      />
                      <MDButton
                        component="button"
                        variant="body2"
                        onClick={() => handleRemoveImages(index)}
                        fullWidth
                        style={{ marginTop: "6px" }}
                      >
                        Remove the photo <DeleteIcon />
                      </MDButton>
                    </ImageListItem>
                  ))}
              </ImageList>
            ) : null}
          </Grid>
        </Grid>
      </MDBox>
      <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
        <MDButton variant="gradient" color="info" onClick={handleClick}>
          {type === "add" ? "Add Announcement" : "Update Announcement"}
        </MDButton>
      </Grid>
    </DashboardLayout>
  );
};

export default AnnouncementForm;
